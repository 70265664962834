import React from "react";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import PageHelmet from "../../components/shared/PageHelmet";
import HomeAbout from "../../pages/Home/HomeAbout/HomeAbout";
import Footer from "../sections/Footer";
import Header from "../sections/Header";
import ServicesGrid from "../sections/ServicesGrid";

export default function Services(props) {
  return (
    <>
      <PageHelmet pageTitle="Services" />
      <Header />
      <CommonPageHeader title="Services" subtitle="Services" />
      <ServicesGrid />
      <Footer />
    </>
  );
}
