import React, { useState } from "react";
import { FaFacebookF, FaTwitter, FaVimeoV } from "react-icons/fa";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";

const HomeTwoFooter = () => {
  const [formSubmitting, setFormSubmitting] = useState(false);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setFormSubmitting(true);
    const email = document.getElementById("sub-email").value;
    const formData = new FormData();
    formData.append("access_key", "cc5cacd4-0086-4ef2-bfd5-cf810418138a");
    formData.append("name", email);
    formData.append("email", email);
    formData.append("message", "Want to subscribe for newsletter");

    console.log(formData);
    axios
      .post("https://api.web3forms.com/submit", formData)
      .then((resp) => {
        console.log({ resp });
        alert("Hurray...! Successfully requested for newsletter subscription.");
        //   setFormSubmitting(false);
      })
      .catch((error) => {
        console.error({ error });
        alert("Oops...! Failed to subscribe for newsletter. Please try again");
        setFormSubmitting(false);
      })
      .finally(() => {
        document.getElementById("sub-form").reset();
      });
  };
  return (
    <>
      <footer>
        <div className="footer__area grey-bg pt-100">
          <div className="footer__top pb-45">
            <div className="container">
              <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                  <div className="footer__widget mb-45">
                    <div className="footer__widget-title mb-30">
                      <div className="logo">
                        <Link to="/">
                          <img src="assets/img/logo/logo-gradient.svg" alt="" />
                        </Link>
                      </div>
                    </div>
                    <div className="footer__widget-content">
                      <p className="mb-30">
                        Copyright © {new Date().getFullYear()} All Rights
                        Reserved. Xenotive
                      </p>
                      {/* <div className="footer__social theme-social mb-30">
                                    <ul>
                                       <li>
                                          <a href="#">
                                             <i ><FaFacebookF /></i>
                                             <i ><FaFacebookF /></i>
                                          </a>
                                       </li>
                                       <li>
                                          <a href="#">
                                             <i ><FaTwitter /> </i>
                                             <i ><FaTwitter /> </i>
                                          </a>
                                       </li>
                                       <li>
                                          <a href="#">
                                             <i ><FaVimeoV /> </i>
                                             <i ><FaVimeoV /> </i>
                                          </a>
                                       </li>
                                    </ul>
                                 </div> */}
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 offset-xl-1">
                  <div className="footer__widget mb-45">
                    <div className="footer__widget-title">
                      <h4>Company</h4>
                    </div>
                    <div className="footer__widget-content">
                      <div className="footer__links">
                        <ul>
                          <li>
                            <NavLink to="/">Home</NavLink>
                          </li>
                          <li>
                            <NavLink to="/about">About Us</NavLink>
                            {/* <a href="#">Testimonials</a> */}
                          </li>
                          <li>
                            <NavLink to="/services">Services</NavLink>

                            {/* <a href="#">Consulting</a> */}
                          </li>
                          <li>
                            <NavLink to="/contact">Contact Us</NavLink>
                            {/* <a href="#">Partners</a> */}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 offset-xl-1">
                           <div className="footer__widget mb-45">
                              <div className="footer__widget-title">
                                 <h4>Resources</h4>
                              </div>
                              <div className="footer__widget-content">
                                 <div className="footer__links">
                                    <ul>
                                       <li><a href="#">Customers</a></li>
                                       <li><a href="#">Pricing</a></li>
                                       <li><a href="#">News</a></li>
                                       <li><a href="#">Learning Center</a></li>
                                       <li><a href="#">Help desk</a></li>
                                       <li><a href="#">Support</a></li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div> */}
                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6">
                  <div className="footer__widget mb-45">
                    <div className="footer__widget-title">
                      <h4>Newsletter</h4>
                    </div>
                    <div className="footer__widget-content">
                      <div className="footer__subscribe">
                        <p className="mb-30">
                          Subscribe to our newsletter today to receive updates
                          and latest news
                        </p>
                        <div className="footer__subscribe-form p-relative">
                          <form id="sub-form" onSubmit={handleFormSubmit}>
                            <input
                              type="email"
                              required
                              name="email"
                              placeholder="Email Address"
                              id="sub-email"
                            />
                            <button type="submit" disabled={formSubmitting}>
                              Subscribe
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default HomeTwoFooter;
