import React, { useEffect } from "react";
import WOW from "wowjs";

import { BiConversation, BiBulb } from "react-icons/bi";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { IoIosPeople } from "react-icons/io";
import { GiShakingHands, GiStairsGoal } from "react-icons/gi";
// TbHeartHandshake IoIosPeople
// BsFillPatchCheckFill

import "./__sections.scss";

const CoreValues = (props) => {
  useEffect(() => {
    window.wow = new WOW.WOW({
      live: false,
      delay: "0.3s",
    });

    window.wow.init();
  }, []);

  return (
    <>
      <section
        className="core_values"
        id="core_values"
        style={{ backgroundImage: "url('/assets/img/services/world_map.svg')" }}
      >
        <div className="container pt-100 pb-100">
          <div className="row">
            <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
              <div
                className="section__title section__title-3 text-center mb-40 wow fadeInUp"
                data-wow-delay=".2s"
              >
                <span>Core Values</span>
                <h2>Our core values define us</h2>
              </div>
            </div>
            <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 pt-60">
              <div className="row">
                <div className="col-12 mb-30 col-md-6">
                  <div
                    className="core_value_value wow fadeInUp"
                    data-wow-delay=".4s"
                  >
                    <div className="core_value_icon">
                      <BiConversation />
                    </div>
                    <div className="core_value_content">
                      Crystal Clear Communication
                    </div>
                  </div>
                </div>
                <div className="col-12 mb-30 col-md-6">
                  <div
                    className="core_value_value wow fadeInUp"
                    data-wow-delay=".4s"
                  >
                    <div className="core_value_icon">
                      <BiBulb />
                    </div>
                    <div className="core_value_content">
                      Innovation &amp; Passion
                    </div>
                  </div>
                </div>
                <div className="col-12 mb-30 col-md-6">
                  <div
                    className="core_value_value wow fadeInUp"
                    data-wow-delay=".4s"
                  >
                    <div className="core_value_icon">
                      <BsFillPatchCheckFill />
                    </div>
                    <div className="core_value_content">Integrity</div>
                  </div>
                </div>
                <div className="col-12 mb-30 col-md-6">
                  <div
                    className="core_value_value wow fadeInUp"
                    data-wow-delay=".4s"
                  >
                    <div className="core_value_icon">
                      <GiShakingHands />
                    </div>
                    <div className="core_value_content">Diversity</div>
                  </div>
                </div>
                <div className="col-12 mb-30 col-md-6">
                  <div
                    className="core_value_value wow fadeInUp"
                    data-wow-delay=".4s"
                  >
                    <div className="core_value_icon">
                      <GiStairsGoal />
                    </div>
                    <div className="core_value_content">Ambitious</div>
                  </div>
                </div>
                <div className="col-12 mb-30 col-md-6">
                  <div
                    className="core_value_value wow fadeInUp"
                    data-wow-delay=".4s"
                  >
                    <div className="core_value_icon">
                      <IoIosPeople />
                    </div>
                    <div className="core_value_content">
                      Customer &amp; Employee Focused
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CoreValues;
