import React from "react";
import { Link } from "react-router-dom";
import { handle_btn_click } from "../../pages/Contact/contact_scroll";

const SIngleContactInfo = ({ image, title, btn_text }) => {
  //   const handle_btn_click = () => {
  //     const element = document.getElementById("contact_form");
  //     if (element) {
  //       const y = element.getBoundingClientRect().top + window.scrollY - 120;
  //       window.scroll({
  //         top: y,
  //         behavior: "smooth",
  //       });
  //     }
  //   };

  return (
    <>
      <div className="col-xl-5 col-lg-6 col-md-6 offset-xl-1">
        <div
          className="contact__help-item white-bg text-center mb-30 wow fadeInLeft"
          data-wow-delay=".3s"
        >
          <div className="contact__icon mb-35">
            <img
              src={`assets/img/icon/contact/${image}.png`}
              alt="contact_us_icon"
            />
          </div>
          <div className="contact__text">
            <h3 className="mb-4">{title}</h3>
            {/* <p>Naff the wireless barney bodge lurgy cuppa cheeky.</p> */}
            <button className="z-btn z-btn-border" onClick={handle_btn_click}>
              {btn_text}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SIngleContactInfo;
