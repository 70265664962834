import React from "react";
import HomeTwoSingleSlide from "../../../components/HomeTwoSingleSlide/HomeTwoSingleSlide";
import Slider from "react-slick";

const HomeTwoHeroSlider = () => {
  // slider setting
  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    infinite: true,
    dots: false,
    fade: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <section className="slider__area slider__area-2">
        <Slider className="slider-active" {...settings}>
          <HomeTwoSingleSlide
            slider_class="col-xl-7 offset-xl-6 col-lg-8 offset-lg-4 col-md-9 offset-md-3 col-sm-10 offset-sm-2"
            content_class=""
            attr_2={
              <h1>
                Small enough to Care <br /> Big enough to Commit
              </h1>
            }
            bg_img="assets/img/slider/02/slider-1.jpg"
            btn_text="Know more"
            btn_link_to="/about"
          />

          <HomeTwoSingleSlide
            slider_class="col-xl-7 offset-xl-6 col-lg-8 offset-lg-4 col-md-9 offset-md-3 col-sm-10 offset-sm-2"
            content_class="slider__content-1"
            attr_2={
              <h1>
                SAP on CLOUD is
                <br /> not just a migration,
                <br />
                it's a JOURNEY...
              </h1>
            }
            bg_img="assets/img/slider/02/slider-2.png"
            btn_text="Get in touch"
            btn_link_to="/contact#get_in_touch"
            attr_3="Success in the public cloud requires a complete mind shift change"
          />

          {/* <HomeTwoSingleSlide slider_class="col-xl-6" content_class="slider__content-4" attr_1={<span >Welcome To Zibber.</span>} attr_2={<h1 >Get more Impact Fasted</h1>} attr_3="Tinkety tonk old fruit baking cakes cobblers happy days argy-bargy up the duff excuse my french fanny around." btn_text="See what's New" /> */}
        </Slider>
      </section>
    </>
  );
};

export default HomeTwoHeroSlider;
