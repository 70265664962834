import React from "react";
import CommonCtaArea from "../../components/CommonCtaArea/CommonCtaArea";
// import CoreValues from "../../components/CoreValues/CoreValues";
// import OurVision from "../../components/OurVision/OurVision";
import PageHelmet from "../../components/shared/PageHelmet";
import CoreValues from "../../xenotive/sections/CoreValues";
import Header from "../../xenotive/sections/Header";
import HeroCarousel from "../../xenotive/sections/HeroCarousel";
import OurVision from "../../xenotive/sections/OurVison";
import Team from "../../xenotive/sections/Team";
import Technologies from "../../xenotive/sections/Technologies";
import TeamArea from "../Team/TeamArea/TeamArea";
import HomeTwoAchievement from "./HomeTwoAchievement/HomeTwoAchievement";
import HomeTwoBlogs from "./HomeTwoBlogs/HomeTwoBlogs";
import HomeTwoCaseArea from "./HomeTwoCaseArea/HomeTwoCaseArea";
import HomeTwoCounter from "./HomeTwoCounter/HomeTwoCounter";
import HomeTwoExpertArea from "./HomeTwoExpertArea/HomeTwoExpertArea";
// import HomeTwoFaq from './HomeTwoFaq/HomeTwoFaq';
import HomeTwoFeatures from "./HomeTwoFeatures/HomeTwoFeatures";
import HomeTwoFooter from "./HomeTwoFooter/HomeTwoFooter";
import HomeTwoHeader from "./HomeTwoHeader/HomeTwoHeader";
import HomeTwoHeroSlider from "./HomeTwoHeroSlider/HomeTwoHeroSlider";
import HomeTwoTestimonial from "./HomeTwoTestimonial/HomeTwoTestimonial";

const HomeTwo = () => {
  return (
    <>
      <PageHelmet pageTitle="Home" />
      <Header />
      <HeroCarousel />
      <Technologies />
      <OurVision />
      <CoreValues />
      <Team />
      {/* <HomeTwoFeatures/> */}
      {/* <HomeTwoFaq/> */}
      {/* <HomeTwoAchievement/>
         <HomeTwoExpertArea/>
         <HomeTwoCounter/>
         <HomeTwoTestimonial/>
         <HomeTwoCaseArea/>
         <HomeTwoBlogs/>
         <CommonCtaArea/> */}
      <HomeTwoFooter />
    </>
  );
};

export default HomeTwo;
