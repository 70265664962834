import React, { useEffect, useState, useLayoutEffect } from "react";
// import Home_Vision_1 from '../../../public/'
import "./__sections.scss";

import { GrSteps } from "react-icons/gr";
import { GoGlobe } from "react-icons/go";
import { GiCornerFlag } from "react-icons/gi";
import { SiSpeedtest } from "react-icons/si";

import WOW from "wowjs";

const OurVision = (props) => {
  useEffect(() => {
    window.wow = new WOW.WOW({
      live: false,
      delay: "0.3s",
    });

    window.wow.init();
  }, []);

  return (
    <>
      <section className="our_values__area  pt-115 pb-80" id="our_vision">
        <img
          src="/assets/img/services/background-abstract-06.svg"
          alt=""
          className="background-abstract z-1 abs-7"
        />
        <img
          src="/assets/img/services/background-abstract-08.svg"
          alt=""
          className="background-abstract z-1 abs-9"
        />
        <img
          src="/assets/img/services/background-abstract-07.svg"
          alt=""
          className="background-abstract z-1 abs-8"
        />
        <img
          src="/assets/img/services/background-abstract-10.svg"
          alt=""
          className="background-abstract z-1 abs-10"
        />
        <div className="container">
          <div className="row">
            <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
              <div
                className="section__title section__title-3 text-center mb-40 wow fadeInUp"
                data-wow-delay=".2s"
              >
                {/* <span>Our Services</span> */}
                <h2>Our Vision</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-10 offset-xl-1 col-lg-10 col-sm-12 offset-lg-1">
              <div className="row our-vision-row justify-content-between align-items-stretch pt-60 pb-60">
                <div className="col col-12 col-md-6 col-lg-4 col-xl-3">
                  <div
                    className="vision-card wow fadeInUp"
                    data-wow-delay=".4s"
                  >
                    <div className="vision-icon">
                      <GiCornerFlag />
                    </div>
                    <h3 className="vision-title">Thought Leadership</h3>
                    <p className="vision-description">
                      Be Recognized for Thought Leadership in our focus areas of
                      Technology &amp; Innovation
                    </p>
                  </div>
                </div>
                <div className="col col-12 col-md-6 col-lg-4 col-xl-3">
                  <div
                    className="vision-card wow fadeInUp"
                    data-wow-delay=".6s"
                  >
                    <div className="vision-icon">
                      <GoGlobe />
                    </div>
                    <h3 className="vision-title">World Class Solutions</h3>
                    <p className="vision-description">
                      Build a world-class team to provide the world-class
                      solutions
                    </p>
                  </div>
                </div>
                <div className="col col-12 col-md-6 col-lg-4 col-xl-3">
                  <div
                    className="vision-card wow fadeInUp"
                    data-wow-delay=".8s"
                  >
                    <div className="vision-icon">
                      <SiSpeedtest />
                    </div>
                    <h3 className="vision-title">Accelarated Profitability</h3>
                    <p className="vision-description">
                      Accelerate Profitability both for Xenotive and Customers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurVision;
