import React from "react";
import { BiLock } from "react-icons/bi";
import { CgArrowLongRight } from "react-icons/cg";
import { FaLightbulb, FaRibbon, FaBrain } from "react-icons/fa";
import { IoDocumentTextOutline } from "react-icons/io5";
import { RiTeamFill } from "react-icons/ri";
import { MdAutoFixHigh } from "react-icons/md";
import { Link } from "react-router-dom";
import HomeTwoSingleFeature from "../../../components/HomeTwoSingleFeature/HomeTwoSingleFeature";



const WeDeliverBest = () => {
  return (
    <>
      <section className="features__area pt-115 pb-120">
        <div className="container about__content">
          <div className="row ">
            <div className="col-xl-5 col-lg-6">
              <div className="features__content-left">
                <div className="section__title section__title-3 mb-25">
                  <span>How Do We Deliver The Best?</span>
                  <h2>Start-ups at incubation to Global giants</h2>
                </div>
                <p>
                  From start-ups at incubation, to global giants at the top of
                  Fortune 500 list, we work with businesses of all sizes. We
                  have a culture of helping businesses create fast, efficient
                  bespoke solutions that solve their real-world problems
                  through:
                </p>

                <p>
                We believe that to provide a company with unstinted success, it requires constant determination and distinguished efforts, tuning with the newly evolving technologies in the market. We value our people and our people value just one goal – enabling global innovation with cutting-edge digital technologies.
                </p>

                <p>
                Talk to our digital transformation experts today, so that we can help your business grow fast & improve operational efficiency.
                </p>
                {/* <Link to="/about" className="z-btn">
                  What we do
                  <i>
                    <CgArrowLongRight />
                  </i>
                </Link> */}
              </div>
            </div>
            <div className="col-xl-6 offset-xl-1 col-lg-6">
              <div className="features__content-right">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                    <HomeTwoSingleFeature
                      icon={<FaLightbulb />}
                      title="Best Technology for Future Readiness"
                    />
                    <HomeTwoSingleFeature
                      icon={<RiTeamFill />}
                      title="Strong team of Professionals to Support Our Customers 24X7"
                    />
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                    <HomeTwoSingleFeature
                      icon={<FaBrain />}
                      title="Accelerating Business With Intelligent Systems"
                    />
                    <HomeTwoSingleFeature
                      icon={<MdAutoFixHigh />}
                      title="Productive Culture That Encourages Creativity"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WeDeliverBest;
