import React, { useState } from "react";

import axios from "axios";

const ContactArea = () => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const handleFormSubmit = (e) => {
    e.preventDefault();
    setFormSubmitting(true);
    console.log(e);

    const name_input = document.getElementById("form_name").value;
    const email_input = document.getElementById("form_email").value;
    const message_input = document.getElementById("form_message").value;
    console.log({ name_input, email_input, message_input });
    const formData = new FormData();
    formData.append("access_key", "cc5cacd4-0086-4ef2-bfd5-cf810418138a");
    formData.append("name", name_input);
    formData.append("email", email_input);
    formData.append("message", message_input);

    console.log({ formData });
    axios
      .post("https://api.web3forms.com/submit", formData)
      .then((resp) => {
        console.log({ resp });
        alert(
          "Query submitted successfully. one of our executive will reach you soon."
        );
        setFormSubmitting(false);
      })
      .catch((error) => {
        console.error({ error });
        alert("Oops...! Failed to submit your query. Please try again.");
        setFormSubmitting(false);
      })
      .finally(() => {
        document.getElementById("contact_form").reset();
      });
  };
  return (
    <>
      <section className="contact__area">
        <div className="container-fluid p-0">
          <div className="row g-0">
            <div className="col-xl-12">
              <div className="contact__map">
                <iframe
                  title="contact"
                  src="https://maps.google.com/maps?hl=en&amp;q=H+M+Tech+Park+()&amp;ie=UTF8&amp;t=&amp;z=10&amp;iwloc=B&amp;output=embed"
                ></iframe>
                <div
                  className="contact__wrapper d-md-flex justify-content-between wow fadeInUp"
                  data-wow-delay=".3s"
                >
                  <div className="contact__info mr-100">
                    <h3 id="get_in_touch">Get in touch</h3>
                    <ul>
                      <li>
                        <h4>Address</h4>
                        <p>
                          HM Tech Park <br /> Ground floor, Unit No – 003 ,
                          Wing-I Block
                        </p>
                        <p>
                          Pattandur Agrahara Village, K.R. Puram Hobli,
                          Bangalore 560066
                        </p>
                      </li>
                      <li>
                        <h4>call us</h4>
                        <p>
                          <a href="tel:+918197755520">(+91) 8197755520</a>
                        </p>
                        <p>
                          <a href="tel:+919818549000">(+91) 9818549000</a>
                        </p>
                      </li>
                      <li>
                        <h4>Email Address</h4>
                        <p>
                          <a href="mailto:Info@example.com">
                            Info@xenotive.com
                          </a>
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="contact__form">
                    <form id="contact_form" onSubmit={handleFormSubmit}>
                      {/* <input
                        type="hidden"
                        name="access_key"
                        value="cc5cacd4-0086-4ef2-bfd5-cf810418138a"
                      ></input> */}
                      <input
                        id="form_name"
                        type="text"
                        required
                        name="Name"
                        placeholder="Your Name"
                      />
                      <input
                        id="form_email"
                        type="email"
                        required
                        name="email"
                        placeholder="Your Email"
                      />
                      <textarea
                        id="form_message"
                        required
                        name="message"
                        placeholder="Your Message"
                      ></textarea>
                      <button
                        disabled={formSubmitting}
                        type="submit"
                        className="z-btn"
                      >
                        Send Message
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactArea;
