export const handle_btn_click = (location) => {
  const element = document.getElementById("contact_form");
  if (element) {
    const y = element.getBoundingClientRect().top + window.scrollY - 150;
    console.log({
      y,
      bound: element.getBoundingClientRect().top,
      scrollY: window.scrollY,
    });
    window.scroll({
      top: y,
      behavior: "smooth",
    });
  }
};
