import React from "react";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import Footer from "../../components/shared/Footer";
import PageHelmet from "../../components/shared/PageHelmet";
import AboutUsTeam from "../Home/HomeAbout/AboutUsTeam";
import HomeAbout from "../Home/HomeAbout/HomeAbout";
import HomeBrands from "../Home/HomeBrands/HomeBrands";
import HomeOneHeader from "../Home/HomeOneHeader/HomeOneHeader";
import AboutUsDescription from "../HomeTwo/AboutUsDescription/AboutUsDescription";
import HomeTwoAchievement from "../HomeTwo/HomeTwoAchievement/HomeTwoAchievement";
import HomeTwoFeatures from "../HomeTwo/HomeTwoFeatures/HomeTwoFeatures";
import HomeTwoFooter from "../HomeTwo/HomeTwoFooter/HomeTwoFooter";
import HomeTwoHeader from "../HomeTwo/HomeTwoHeader/HomeTwoHeader";
import TeamOfExcellence from "../HomeTwo/TeamOfExcellence/TeamOfExcellence";
import WeDeliverBest from "../HomeTwo/WeDeliverBest/WeDeliverBest";
import WeDeliverTheBest from "../HomeTwo/WeDeliverTheBest/WeDeliverTheBest";

const About = () => {
  return (
    <>
      <PageHelmet pageTitle="About" />
      <HomeTwoHeader />
      <CommonPageHeader title="About Us" subtitle="About" />
      <HomeAbout />
      <AboutUsDescription />
      <TeamOfExcellence />
      {/* <WeDeliverTheBest/> */}
      <WeDeliverBest />
      <HomeTwoFooter />
    </>
  );
};

export default About;
