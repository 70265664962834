import React, { useEffect } from "react";
import { FaLinkedin } from "react-icons/fa";

import "./__sections.scss";

import WOW from "wowjs";

const Team = (props) => {
  useEffect(() => {
    window.wow = new WOW.WOW({
      live: false,
      delay: "0.3s",
    });

    window.wow.init();
  }, []);
  return (
    <>
      <section
        id="team_xenotive"
        style={{ backgroundImage: "url('/assets/img/home/team_bg.jpg')" }}
      >
        <div className="container pt-100 pb-100">
          <div className="row">
            <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
              <div
                className="section__title section__title-3 text-center mb-40 wow fadeInUp"
                data-wow-delay=".2s"
              >
                <span>Our Team</span>
                <h2>Team Xenotive</h2>
              </div>
            </div>
            <div className="col-12">
              <div className="row align-items-center justify-content-center">
                <div className="col-12 col-md-4 col-xl-3">
                  <div className="team-card wow fadeInUp" data-wow-delay=".4s">
                    <div className="card-image">
                      <img
                        src="/assets/img/home/samarjeet.jpeg"
                        alt="Samarjeet Singh"
                      />
                    </div>
                    <div className="card-details">
                      <h3 className="team-name  mb-1">Samarjeet Singh</h3>
                      <p className="my-0 text-center">CEO &amp; Co-founder</p>
                    </div>
                    <div className="card-cta mt-2">
                      <a
                        href="https://www.linkedin.com/in/samarjeet-singh-7a06a284/"
                        target="_blank"
                        className="btn btn-primary"
                      >
                        <FaLinkedin /> LinkedIn
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-xl-3">
                  <div className="team-card wow fadeInUp" data-wow-delay=".6s">
                    <div className="card-image">
                      <img
                        src="/assets/img/home/anurag.jpeg"
                        alt="Samarjeet Singh"
                      />
                    </div>
                    <div className="card-details">
                      <h3 className="team-name  mb-1">Anurag Magoo</h3>
                      <p className="my-0 text-center">
                        Director &amp; Co-founder
                      </p>
                    </div>
                    <div className="card-cta">
                      <a
                        href="https://www.linkedin.com/in/anurag-magoo-723b242/"
                        target="_blank"
                        className="btn btn-primary"
                      >
                        <FaLinkedin /> LinkedIn
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Team;

// lighten($color, $amount)
