import React from "react";
import { FaCheck, FaUserShield } from "react-icons/fa";
import { RiRefreshLine } from "react-icons/ri";
import { TbCloudDataConnection } from "react-icons/tb";
import { MdWeb } from "react-icons/md";
import { BsHddNetworkFill } from "react-icons/bs";
import { SiWindowsterminal } from "react-icons/si";

import WOW from "wowjs";

import "./__sections.scss";
import { useEffect } from "react";

export default function ServicesGrid(props) {
  useEffect(() => {
    window.wow = new WOW.WOW({
      live: false,
      delay: "0.3s",
    });

    window.wow.init();
  }, []);

  return (
    <>
      <section className="services">
        <img
          src="/assets/img/services/background-abstract-01.png"
          alt=""
          className="background-abstract abs-1"
        />
        <img
          src="/assets/img/services/background-abstract-02.png"
          alt=""
          className="background-abstract abs-2"
        />
        <img
          src="/assets/img/services/background-abstract-03.png"
          alt=""
          className="background-abstract abs-3"
        />
        <img
          src="/assets/img/services/background-abstract-04.png"
          alt=""
          className="background-abstract abs-4"
        />
        <div className="container">
          <div className="row">
            <div className="col-12 mt-100 mb-100">
              <div className="text-center d-block mb-100">
                <h2 className="display-3 services-heading">Services Offered</h2>
              </div>
              <div className="container">
                <div className="row services-list-grid justify-content-center align-items-stretch">
                  {/* Service card 1 */}
                  <div className="col-12 col-md-6">
                    <div
                      className="services-list-item wow fadeInUp"
                      data-wow-delay="0s"
                      data-wow-duration="0.8s"
                    >
                      <div className="service-icon">
                        <i>
                          <RiRefreshLine />
                        </i>
                      </div>
                      <div className="service-content">
                        <div className="service-title">
                          <h3>Supporting the Lifecycle</h3>
                        </div>
                        <div className="service-sub-list">
                          <div className="about__list">
                            <ul>
                              <li>
                                <span>
                                  <i>
                                    <FaCheck />
                                  </i>
                                </span>
                                Advisory
                              </li>
                              <li>
                                <span>
                                  <i>
                                    <FaCheck />
                                  </i>
                                </span>
                                Delivery
                              </li>
                              <li>
                                <span>
                                  <i>
                                    <FaCheck />
                                  </i>
                                </span>
                                Support
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Service card 2 */}
                  <div className="col-12 col-md-6">
                    <div
                      className="services-list-item wow fadeInUp"
                      data-wow-delay="0.3s"
                      data-wow-duration="0.8s"
                    >
                      <div className="service-icon">
                        <i>
                          <TbCloudDataConnection />
                        </i>
                      </div>
                      <div className="service-content">
                        <div className="service-title">
                          <h3>Cloud Enablement</h3>
                        </div>
                        <div className="service-sub-list">
                          <div className="about__list">
                            <ul>
                              <li>
                                <span>
                                  <i>
                                    <FaCheck />
                                  </i>
                                </span>
                                Assessment
                              </li>
                              <li>
                                <span>
                                  <i>
                                    <FaCheck />
                                  </i>
                                </span>
                                Migration
                              </li>
                              <li>
                                <span>
                                  <i>
                                    <FaCheck />
                                  </i>
                                </span>
                                Greenfield
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Service card 3 */}
                  <div className="col-12 col-md-6">
                    <div
                      className="services-list-item wow fadeInUp"
                      data-wow-delay="0.6s"
                      data-wow-duration="0.8s"
                    >
                      <div className="service-icon">
                        <i>
                          <MdWeb />
                        </i>
                      </div>
                      <div className="service-content">
                        <div className="service-title">
                          <h3>Application Development</h3>
                        </div>
                        <div className="service-sub-list">
                          <div className="about__list">
                            <ul>
                              <li>
                                <span>
                                  <i>
                                    <FaCheck />
                                  </i>
                                </span>
                                SAP BTP Development and Associated Integrations
                              </li>
                              <li>
                                <span>
                                  <i>
                                    <FaCheck />
                                  </i>
                                </span>
                                UI5 &amp; FIORI Developments
                              </li>
                              <li>
                                <span>
                                  <i>
                                    <FaCheck />
                                  </i>
                                </span>
                                Development Capabilities in Analytic
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Service card 4 */}
                  <div className="col-12 col-md-6">
                    <div
                      className="services-list-item wow fadeInUp"
                      data-wow-delay="0.9s"
                      data-wow-duration="0.8s"
                    >
                      <div className="service-icon">
                        <i>
                          <FaUserShield />
                        </i>
                      </div>
                      <div className="service-content">
                        <div className="service-title">
                          <h3>SAP Security &amp; Authorization and GRC</h3>
                        </div>
                        <div className="service-sub-list">
                          <div className="about__list">
                            <ul>
                              <li>
                                <span>
                                  <i>
                                    <FaCheck />
                                  </i>
                                </span>
                                HANA Database
                              </li>
                              <li>
                                <span>
                                  <i>
                                    <FaCheck />
                                  </i>
                                </span>
                                NetWeaver
                              </li>
                              <li>
                                <span>
                                  <i>
                                    <FaCheck />
                                  </i>
                                </span>
                                S4
                              </li>
                              <li>
                                <span>
                                  <i>
                                    <FaCheck />
                                  </i>
                                </span>
                                GRC
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Service card 5 */}
                  <div className="col-12 col-lg-4">
                    <div
                      className="services-list-item vertical-card wow fadeInUp"
                      data-wow-delay="1.2s"
                      data-wow-duration="0.8s"
                    >
                      <div className="service-icon style-2">
                        <i>
                          <BsHddNetworkFill />
                        </i>
                      </div>
                      <div className="service-content large-card">
                        <div className="service-title">
                          <h3>Digital Transformation </h3>
                        </div>
                        <div className="about__list">
                          <ul className="mb-50">
                            <li>
                              <span>
                                <i>
                                  <FaCheck />
                                </i>
                              </span>
                              Digital transformation is enabled by Platform
                              Strategy, Process Design and Experience design.
                            </li>
                            <li>
                              <span>
                                <i>
                                  <FaCheck />
                                </i>
                              </span>
                              SAP S/4HANA Conversions and Greenfield
                              Implementations, and an intelligent suite of SAP
                              products including SAP CX, SAP Ariba and SAP
                              SuccessFactors.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Service card 6 */}
                  <div className="col-12 col-lg-8">
                    <div
                      className="services-list-item vertical-card wow fadeInUp"
                      data-wow-duration="0.8s"
                      data-wow-delay="1.5s"
                    >
                      <div className="service-icon style-2">
                        <i>
                          <SiWindowsterminal />
                        </i>
                      </div>
                      <div className="service-content large-card">
                        <div className="service-title">
                          <h3>Application Management</h3>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <div className="about__list">
                              <ul className="mb-4">
                                <li>
                                  <span>
                                    <i>
                                      <FaCheck />
                                    </i>
                                  </span>
                                  High Availability - 24X7X365 support model
                                </li>
                                <li>
                                  <span>
                                    <i>
                                      <FaCheck />
                                    </i>
                                  </span>
                                  Manage results rather than people
                                </li>
                                <li>
                                  <span>
                                    <i>
                                      <FaCheck />
                                    </i>
                                  </span>
                                  Higher offshore work to reduce the cost
                                </li>
                                <li>
                                  <span>
                                    <i>
                                      <FaCheck />
                                    </i>
                                  </span>
                                  Increased responsiveness - Co-location of
                                  L1/2/3,
                                </li>
                                <li>
                                  <span>
                                    <i>
                                      <FaCheck />
                                    </i>
                                  </span>
                                  Increased Scalability - accommodate additional
                                  requirements quickly due to the availability
                                  of a pool of experts
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="about__list">
                              <ul className="mb-50">
                                <li>
                                  <span>
                                    <i>
                                      <FaCheck />
                                    </i>
                                  </span>
                                  Efficient knowledge Management - Reuse the
                                  resolution of common issues, increase the
                                  learning curve
                                </li>
                                <li>
                                  <span>
                                    <i>
                                      <FaCheck />
                                    </i>
                                  </span>
                                  Use of Best practice delivery process
                                </li>
                                <li>
                                  <span>
                                    <i>
                                      <FaCheck />
                                    </i>
                                  </span>
                                  Multi-level governance mechanism to ensure the
                                  quality of delivery
                                </li>
                                <li>
                                  <span>
                                    <i>
                                      <FaCheck />
                                    </i>
                                  </span>
                                  The risk-free transition from transformation
                                  projects to application maintenance and
                                  support
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
