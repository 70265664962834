import React from 'react';
import { Link } from 'react-router-dom';

const AboutUsDescription = () => {
   return (
      <>
         <section className="achievement__area pt-135 pb-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-5 col-lg-6 col-md-6">
                     <div className="achievement__content">
                        <div className="section__title section__title-3 mb-20">
                           <span>We are digital</span>
                           <h2>We enable digital transformation</h2>
                        </div>
                        <p>We enable digital transformation for organisations that can deliver seamless customer experiences, actionable insights and business efficiency. We focus on capacitating organisations to remodel their IT, prepare them for new digital wave that can take them light years ahead and derive lasting business value.</p>
                        {/* <div className="achievement__wrapper d-flex d-md-block d-lg-flex justify-content-between mb-35 wow fadeInUp" data-wow-delay=".8s">
                           <div className="achievement__item mb-30">
                              <img src="assets/img/icon/achievement/achievement-1.png" alt="achievement"/>
                                 <h3>Acquisitions Finance Consulting</h3>
                           </div>
                           <div className="achievement__item mb-30">
                              <img src="assets/img/icon/achievement/achievement-2.png" alt="achievement"/>
                                 <h3>Private Placement Consulting</h3>
                           </div>
                        </div>
                        <Link to="/about" className="z-btn">Learn More</Link> */}
                     </div>
                  </div>
                  <div className="col-xl-7 col-lg-6 col-md-6">
                     <div className="row">
                        <div className="col-xl-7 col-sm-6">
                           <div className="achievement__thumb m-img pl-30 text-right">
                              <img src="assets/img/about/we_enable_digital.png" alt="achievement-1"/>
                           </div>
                        </div>
                        <div className="col-xl-5 col-sm-6 d-md-none d-lg-none d-xl-block">
                           <div className="achievement__thumb w-img">
                              <img src="assets/img/achievement/achievement-2.jpg" alt=""/>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default AboutUsDescription;