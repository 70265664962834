import React from "react";
import "./__sections.scss";

const TechnologyCard = ({ tech_img, tech_title }) => {
  return (
    <>
      <div className="technology-card">
        <div className="card-thumb text-center">
          <img src={tech_img} alt={tech_title} />
        </div>
        <div className="card-title">
          <h3 className="text-center mt-60">{tech_title}</h3>
        </div>
      </div>
    </>
  );
};

const Technologies = (props) => {
  return (
    <>
      <section className="technologies  pb-100" id="technologies_section">
        <img
          src="/assets/img/services/background-abstract-01.png"
          alt=""
          className="background-abstract abs-6"
        />
        {/* <img
          src="/assets/img/services/background-abstract-02.png"
          alt=""
          className="background-abstract abs-2"
        /> */}
        <img
          src="/assets/img/services/background-abstract-05.svg"
          alt=""
          className="background-abstract abs-5"
        />
        {/* <img
          src="/assets/img/services/background-abstract-04.png"
          alt=""
          className="background-abstract abs-4"
        /> */}
        <div className="container">
          <div className="row mt-100 mb-60">
            <div className="col-12">
              <div
                className="section__title section__title-3 text-center mb-40 wow fadeInUp"
                data-wow-delay=".2s"
              >
                <span>Technologies</span>
                <h2>Technologies we expertise in</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-4 mb-50">
              <TechnologyCard
                tech_img={"/assets/img/technologies/sap.svg"}
                tech_title="SAP"
              />
            </div>
            <div className="col-12 col-md-4 mb-50">
              <TechnologyCard
                tech_img={"/assets/img/technologies/application_dev.svg"}
                tech_title="Application Modernization"
              />
            </div>
            <div className="col-12 col-md-4 mb-50">
              <TechnologyCard
                tech_img={"/assets/img/technologies/salesforce.svg"}
                tech_title="Sales Force"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Technologies;
