import React, { useEffect, useState } from "react";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import Footer from "../../components/shared/Footer";
import PageHelmet from "../../components/shared/PageHelmet";
import HomeOneHeader from "../Home/HomeOneHeader/HomeOneHeader";
import HomeTwoFooter from "../HomeTwo/HomeTwoFooter/HomeTwoFooter";
import HomeTwoHeader from "../HomeTwo/HomeTwoHeader/HomeTwoHeader";
import ContactArea from "./ContactArea/ContactArea";
import ContactInfoArea from "./ContactInfoArea/ContactInfoArea";

import { useLocation } from "react-router-dom";
import { handle_btn_click } from "./contact_scroll";

const Contact = () => {
  let location = useLocation();
  useEffect(() => {
    if (location.hash == "#get_in_touch") {
      handle_btn_click();
    }
  }, [location]);
  return (
    <>
      <PageHelmet pageTitle="Contact Us" />

      <HomeTwoHeader />
      <CommonPageHeader title="Contact Us" subtitle="Contact" />
      <ContactInfoArea />
      <ContactArea />
      <HomeTwoFooter />
    </>
  );
};

export default Contact;
