import { CgArrowLongRight } from 'react-icons/cg';
import { FaCheck } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const HomeAbout = () => {
   return (
      <>
         <section className="about__area pt-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 pr-0 col-lg-6">
                     <div className="about__thumb m-img">
                        <img src="assets/img/about/about-1.jpg" alt=""/>
                           <div className="about__shape">
                              <img src="assets/img/about/red-shape.png" alt=""/>
                           </div>
                     </div>
                  </div>
                  <div className="col-xl-5 offset-xl-1 col-lg-5 offset-lg-1">
                     <div className="about__content">
                        <div className="section__title mb-25">
                           <span>Who we are</span>
                           <h2>We Unlock Potential</h2>
                        </div>
                        <p>Xenotive is a Digital Business Transformation Company, that facilitates enterprises transition seamlessly in the digital age while overcoming risks.</p>
                        <div className="about__list">
                           <ul>
                              <li ><span><i > <FaCheck/> </i>Building Future With Digital</span></li>
                              <li ><span><i > <FaCheck/> </i>Empowering Tomorrow With Digital</span></li>
                              <li ><span><i > <FaCheck/> </i>Revolutionary Digital For Revolutionary Businesses</span></li>
                              <li ><span><i > <FaCheck/> </i>Powering Next Gen Businesses</span></li>
                           </ul>
                        </div>
                        <p className=''>Originated with an idea to skilfully blend Xeno (meaning different origin) and Technology, we are a company that accelerates transformational engagements through the adoption of mainstream technologies such as SAP, Salesforce mobility, Artificial Intelligence, Machine Learning, Robotic Process Automation and our other AI Infused services.</p>
                        {/* <Link to="/services" className="z-btn " >What we do<i > <CgArrowLongRight /> </i></Link> */}
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeAbout;