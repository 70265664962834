import React from "react";
import { FaCheck } from "react-icons/fa";

const TeamOfExcellence = () => {
  return (
    <>
      <section className="faq__area p-relative pt-135 pb-120 grey-bg-12">
        <div
          className="faq__thumb"
          style={{
            background: `url(assets/img/faq/faq-1.jpg)`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        ></div>
        <div className="container">
          <div className="row about__content">
            <div className="col-xl-5 offset-xl-7 col-lg-6 offset-lg-6 col-md-7 offset-md-5">
              <div className="faq__wrapper">
                <div
                  className="section__title section__title-3 mb-25 wow fadeInUp"
                  data-wow-delay=".2s"
                >
                  <span>Our Team of Excellence</span>
                  <h2>Strategy-led Comprehensive solutions</h2>

                  <p className="mt-30">
                     The IT strategy of a company forms the foundation of the business. At Xenotive, we act as an extension of your team that facilitates in overcoming challenges with comprehensive solutions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TeamOfExcellence;
